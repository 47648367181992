import useAppView from 'Core/Hooks/useAppView'
import { createRef, useAccessCartProvider } from 'features/resources'
import { useAppDetailsContext } from 'features/snowflake'
import { createDataSelectorHook } from 'infra/redux'
import { DeletionModal, IamRolesCard, Label, Typography } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { FullScreenModal, LabelContent } from 'V2Components'
import { reduxApiClient } from 'infra'
import { pushToSlice } from 'infra/redux/sliceHandlers'
const useSlices = createDataSelectorHook([
  'snowFlakeRoles',
  'snowFlakeAccounts',
  'snowFlakeUsers',
  'snowFlakeResources',
  'policyList'
])
const RoleTab = () => {
  const { app } = useAppDetailsContext()
  const { appView } = useAppView()
  const { slices } = useSlices()
  const user = getUserInfo()
  const [selectedRoles, setSelectedRoles] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [currentSnUser, setCurrentSnUser] = useState([])
  const [currentSelectedRole, setCurrentSelectedRole] = useState({})
  const [showRoleDeleteModal, setShowRoleDeleteModal] = useState(false)
  const [showPrivilegesDeleteModal, setShowPrivilegesDeleteModal] = useState(false)
  const [selectedRsrc, setSelectedRsrc] = useState({})
  const [selectedElems, setSelectedElems] = useState([])
  const [selectedRoleObj, setSelectedRoleObj] = useState({})
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const { addItemsToCart, isResourceInCart, isCartEmpty, clearCartItems } = useAccessCartProvider()

  useEffect(() => {
    reduxApiClient('snowflakeroles').getAll({})
    reduxApiClient('pacpolicys').getAll({})
  }, [slices])

  useMemo(() => {
    const filterCurrentAccount = _.find(slices.snowFlakeAccounts, {
      Application: { RefID: app?.ObjectMeta?.ID }
    })

    const filterCurrentAccountUsers = slices.snowFlakeUsers.filter(
      (user) => user.Spec.SnowflakeAccount.RefID === filterCurrentAccount?.ObjectMeta.ID
    )

    const filterRoles = slices.snowFlakeRoles.filter(
      (role) => role.Spec.SnowflakeAccount.RefID === filterCurrentAccount?.ObjectMeta?.ID
    )

    if (appView === 'user') {
      const filterLogedInUser = filterCurrentAccountUsers.filter(
        (users) => users.Spec.Email === user.Spec.EmailID
      )
      if (filterLogedInUser.length) {
        setCurrentSnUser(filterLogedInUser)
        setRolesList(filterRoles)
      } else {
        setRolesList([])
      }
    } else {
      setRolesList(filterRoles)
    }
  }, [slices])

  const getSelectedStatus = (permission) => {
    if (isResourceInCart(createRef(permission))) return true
    return false
  }

  const handleRoleActionClick = (roles) => {
    if (selectedRoles === roles) {
      // If the clicked role is already selected, unselect it
      clearCartItems()
      setSelectedRoles(null)
    } else {
      // Otherwise, select the clicked role
      if (isResourceInCart(createRef(roles)) || !isCartEmpty) return
      addItemsToCart({
        resourceRef: createRef(roles),
        principal: '',
        roles: [createRef(roles)]
      })
      setSelectedRoles(roles)
    }

    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: false
      })
    } else {
      handleViewsChange({
        accessRequestSubmissionModal: true
      })
    }
  }

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  const handleDeletePrivilegesModal = (rsrc, elems, roleObj) => {
    setSelectedRsrc(rsrc)
    setSelectedElems(elems)
    setSelectedRoleObj(roleObj)
    setShowPrivilegesDeleteModal(true)
  }

  const handleRevokePrivilege = async () => {
    const rsrcID = selectedRsrc?.ObjectMeta?.ID
    const updatedRevokedPrivMap = { ...selectedRoleObj?.Spec?.RevokePrivMap?.PrivilegeMap }
    updatedRevokedPrivMap[rsrcID] = {
      Elems: selectedElems
    }

    const updatedRoleObj = {
      ...selectedRoleObj,
      Spec: {
        ...selectedRoleObj?.Spec,
        RevokePrivMap: {
          PrivilegeMap: updatedRevokedPrivMap
        }
      }
    }

    try {
      const response = await reduxApiClient('snowflakeroles').update(updatedRoleObj)
      setTimeout(async () => {
        await reduxApiClient('snowflakeroles').getAll({})
        const roleStatus = slices.snowFlakeRoles.find(
          (role) => role.Spec.Name === response.Spec.Name
        )

        if (roleStatus.Status.Status.Status === 'Fail') {
          setError(roleStatus.Status.Status.Error)
          enqueueNotification(roleStatus.Status.Status.Error, 'error')
        } else {
          await reduxApiClient('snowflakeroles').getAll({})
          setShowPrivilegesDeleteModal(false)
          enqueueNotification('Successfully privileges removed!', 'info')
        }
        setIsLoading(false)
      }, 3000)
    } catch (error) {
      enqueueNotification(error, 'error')
    }
  }

  const getExistingPrivileges = (data, roleObj) => {
    return (
      <div className='mt-5'>
        <Typography variant='body-regular'>Existing Privilege</Typography>
        <div className='mt-5'>
          {Object.entries(data).map(([key, value]) => {
            const elems = [...new Set(value.Elems)]
            const resources = slices.snowFlakeResources.find((rsrc) => rsrc.ObjectMeta.ID === key)
            return (
              <div key={key} className='mt-2 flex items-center'>
                <span className='font-semibold'>{resources?.Spec?.Type}</span>
                <span className='mx-2'>-</span>
                <span className='font-regular '>{resources?.Spec?.DisplayName}</span>
                <span className='mx-2'>-</span>
                <span className='font-medium'>{elems.join(', ')}</span>
                {roleObj.Spec.ManagedBy === 'procyon' && appView === 'admin' && (
                  <span
                    className='ml-3 cursor-pointer'
                    onClick={() => handleDeletePrivilegesModal(resources, elems, roleObj)}
                  >
                    <Label text={'Remove Privilege'} variant='danger' />
                  </span>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const getExpiryFormatedTime = (time) => {
    const date = new Date(time)

    const options = { timeZone: 'Asia/Kolkata', hour12: false }
    const istDate = date.toLocaleString('en-US', options).replace(',', '')

    // Split the date and time for custom formatting
    const [datePart, timePart] = istDate.split(' ')
    const formattedDate = datePart.split('/').reverse().join('-') + '-' + timePart

    return formattedDate
  }

  const getSnowFlakePolicyList = () =>
    slices.policyList.filter((policy) => {
      return policy?.Spec?.ActionMap?.Snowflake?.PolicyRule
    })

  const getGrantedRoles = (grantedObj) => {
    const grantedRolesArray = []

    grantedObj?.ObjectRef?.forEach((objref) => {
      const filteredRole = slices.snowFlakeRoles.filter(
        (role) => role.ObjectMeta.ID === objref.RefID
      )
      grantedRolesArray.push(...filteredRole)
    })

    // check if granted roles refid is exist in pacpolicy
    const snowflakePolicy = getSnowFlakePolicyList()

    const roleLabels = grantedRolesArray.map((role) => {
      const rolePolicies = snowflakePolicy.filter((policy) => {
        const actionMap = policy?.Spec?.ActionMap?.Snowflake?.PolicyRule
        const policyIssuedTo = policy?.IssuedTo?.ObjectRef

        return actionMap.some((pobj) => {
          return pobj.ObjectRef.RefID === role.ObjectMeta.ID
        })
      })

      if (rolePolicies.length) {
        // Get the expiry dates of the policies for the role
        const expiryDates = rolePolicies.map((policy) => policy.NotAfter).join(', ')
        return (
          <Label
            key={role.ObjectMeta.ID}
            variant='grayBlue'
            style={{ marginBottom: '5px' }}
            text={
              <div className='flex items-center'>
                {role.Spec.Name} -{' '}
                {expiryDates && (
                  <span className='text-[red] font-medium ml-1'>
                    Expiry - {getExpiryFormatedTime(expiryDates)}
                  </span>
                )}
              </div>
            }
          />
        )
      }

      return (
        <Label
          key={role.ObjectMeta.ID}
          variant='grayBlue'
          style={{ marginBottom: '5px' }}
          text={<div className='flex items-center'>{role.Spec.Name}</div>}
        />
      )
    })

    return roleLabels
  }

  const handleRevokeRoleClick = (role) => {
    setCurrentSelectedRole(role)
    setShowRoleDeleteModal(true)
  }

  const handleRevokePrivileges = async () => {
    try {
      await reduxApiClient('snowflakeroles').delete(currentSelectedRole)
      setShowRoleDeleteModal(false)
      enqueueNotification('Role Deleted Successfully!', 'info')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className='flex'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        {rolesList &&
          rolesList.map((e, index) => (
            <IamRolesCard
              style={{
                marginBottom: '10px',
                alignItems: 'center'
              }}
              showCustomButton={e.Spec.ManagedBy === 'procyon' && appView === 'admin'}
              customButtonVariant='grayRed'
              customButtonText='Delete'
              onClickCustomButton={() => handleRevokeRoleClick(e)}
              key={index}
              description={e.Spec.Description === '' ? 'Description' : e.Spec.Description}
              selected={getSelectedStatus(e)}
              assigned={getSelectedStatus(e)}
              onClickActionButton={() => handleRoleActionClick(e)}
              title={
                <div className='flex justify-between'>
                  <span className=''>{e.Spec.Name}</span>
                  {e.Spec.ManagedBy && (
                    <span className='flex flex-row text-[gray] items-center'>
                      <span className='text-[12px] font-light'>Managed By : </span>
                      <span> {e.Spec.ManagedBy}</span>
                    </span>
                  )}
                </div>
              }
              showOpenButton={false}
              showMoreButton={Object.keys(e.Status.Privileges.PrivilegeMap).length ? true : false}
            >
              <div>
                {Object.keys(e.Status.Privileges.PrivilegeMap).length > 0 &&
                  getExistingPrivileges(e.Status.Privileges.PrivilegeMap, e)}
              </div>
            </IamRolesCard>
          ))}
      </div>

      {appView === 'user' && currentSnUser.length > 0 && (
        <div className='w-1/2 pr-8'>
          <Typography variant='h3' style={{ padding: '20px 30px' }}>
            Access Details
          </Typography>
          <div>
            <LabelContent
              title='Default Roles'
              className='pl-7'
              content={
                <Label
                  variant='grayBlue'
                  text={
                    <div className='flex items-center'>{currentSnUser[0]?.Spec?.DefaultRole}</div>
                  }
                />
              }
            />
            <LabelContent
              title='Default Warehouse'
              className='pl-7'
              content={
                <Label
                  variant='grayBlue'
                  text={
                    <div className='flex items-center'>
                      {currentSnUser[0]?.Spec?.DefaultWarehouse}
                    </div>
                  }
                />
              }
            />

            <LabelContent
              className='pl-7'
              title='Granted Roles'
              content={getGrantedRoles(currentSnUser[0]?.Spec?.GrantedRoles)}
            />
          </div>
        </div>
      )}
      <FullScreenModal showModal={showRoleDeleteModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <DeletionModal
            message='This action cannot be undone.'
            onClickCancel={() => setShowRoleDeleteModal(false)}
            onClickDelete={() => handleRevokePrivileges()}
            title={`Are you sure you want to delete ${currentSelectedRole?.Spec?.Name} Role?`}
          />
        </div>
      </FullScreenModal>
      <FullScreenModal showModal={showPrivilegesDeleteModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <DeletionModal
            message='This action cannot be undone.'
            onClickCancel={() => setShowPrivilegesDeleteModal(false)}
            onClickDelete={() => handleRevokePrivilege()}
            title={`Are you sure you want to delete Privileges?`}
          />
        </div>
      </FullScreenModal>
    </div>
  )
}

export { RoleTab }
