import useAppView from 'Core/Hooks/useAppView'
import { useCanGoBack } from 'Core/Hooks/useCanGoBack'
import { FullScreenContentModal } from 'V2Components'
import {
  ApplicationDetailsProvider,
  CreateApplicationPolicyFlow,
  CreateApplicationRequestFlow,
  DeleteApplicationModal,
  isReadOnlyApp,
  useAppDetailsContext,
  useApplicationAccess
} from 'features/snowflake'
import {
  AccessCartProvider,
  createRef,
  createRsrcKey,
  useAccessCartProvider
} from 'features/resources'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { AddCloudStore } from '../AddCloudStore'
import { Header } from './components/Header'
import { Tabs } from './components/Tabs'
import { useUser } from 'Core/Hooks/useUser'
import { createDataSelectorHook } from 'infra/redux'
import { reduxApiClient } from 'infra'
import { enqueueNotification } from 'Utils/Helpers'
import { CreateRoleModal } from './components/CreateRoleModal'

const USER_CART_KEY = 'userApplications'
const ADMIN_CART_KEY = 'adminApplications'

const useSlices = createDataSelectorHook([
  'snowFlakeAccounts',
  'snowFlakeRoles',
  'snowFlakeUsers',
  'applicationList'
])

function CloudStoreDetails({}) {
  const [showEditAppModal, setShowEditAppModal] = useState(false)
  const [showDeleteAppModal, setShowDeleteAppModal] = useState(false)
  const [views, setViews] = useState({
    showEntitySlectionModal: false,
    accessRequestSubmissionModal: false
  })
  const history = useHistory()
  const { appView } = useAppView()
  const { currentUserIsAdmin } = useUser()
  const { slices } = useSlices()
  const { addItemsToCart, isResourceInCart, isCartEmpty } = useAccessCartProvider()
  const { app, isAllowedAccess } = useAppDetailsContext()
  const handleEditClick = () => setShowEditAppModal(true)
  const handleDeleteClick = () => setShowDeleteAppModal(true)
  const signInAllowed = !isReadOnlyApp(app) && (currentUserIsAdmin || isAllowedAccess)
  const canGoBack = useCanGoBack()
  const pollingInterval = useRef(null)
  const [showScanning, setShowScanning] = useState(false)
  const { getApplicationRsrcsAccessMap } = useApplicationAccess()
  const accessObject = getApplicationRsrcsAccessMap()
  const [updateState, setUpdateState] = useState(false)
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false)

  useEffect(() => {
    return () => {
      clearInterval(pollingInterval.current)
    }
  }, [])

  useEffect(() => {
    const runCheckScanningStatus = async () => {
      await checkScaningStatus()
    }
    runCheckScanningStatus()
  }, [updateState])

  const handleViewsChange = (v) => {
    setViews((s) => ({ ...s, ...v }))
  }

  useEffect(() => {
    setTimeout(() => {
      setUpdateState(true)
    }, 2000)
  }, [])

  const handleAccessClick = () => {
    if (isResourceInCart(createRef(app)) || !isCartEmpty) return
    addItemsToCart({
      resourceRef: createRef(app),
      principal: '',
      roles: []
    })
    if (appView === 'admin') {
      handleViewsChange({
        showEntitySlectionModal: true
      })
    } else {
      handleViewsChange({
        accessRequestSubmissionModal: true
      })
    }
  }

  const getDisableState = () => {
    if (!isCartEmpty || (appView === 'user' && isAllowedAccess)) {
      return true
    }
  }

  const checkScaningStatus = async () => {
    try {
      const snowFlakeAccounts = await reduxApiClient('snowflakeaccounts').getAll({})
      let filteredAccount = snowFlakeAccounts?.find(
        (obj) => obj.Application.RefID === app.ObjectMeta.ID
      )

      if (filteredAccount) {
        if (filteredAccount?.Status?.Status?.Status === 'started') {
          setShowScanning(true)

          await reduxApiClient('snowflakeaccounts').update(filteredAccount)
          pollingInterval.current = setInterval(async () => {
            const currentAccount = await reduxApiClient('snowflakeaccounts').getAll({})
            filteredAccount = currentAccount?.find(
              (obj) => obj.Application.RefID === app.ObjectMeta.ID
            )

            if (
              filteredAccount?.Status?.Status?.Status === 'success' ||
              filteredAccount?.Status?.Status?.Status === ''
            ) {
              clearInterval(pollingInterval.current)
              enqueueNotification('Sync updated', 'info')
              setShowScanning(false)
            }
          }, 10000)
        } else {
          setShowScanning(false)
        }
      } else {
        setShowScanning(false)
      }
    } catch (error) {
      setShowScanning(false)
    }
  }

  const getSignInStatus = () => {
    let snApp
    if (appView === 'user') {
      const snowflakeRole = accessObject && Object.keys(accessObject).map((obj) => obj.split('+'))
      if (snowflakeRole) {
        snowflakeRole.find((role) => {
          const roleObj = slices.snowFlakeRoles.find((snRole) => snRole.ObjectMeta.ID === role[1])

          const snAccount =
            roleObj &&
            slices.snowFlakeAccounts.find(
              (snAccount) => snAccount.ObjectMeta.ID === roleObj?.Spec?.SnowflakeAccount?.RefID
            )
          snApp =
            snAccount &&
            slices.applicationList.find(
              (apps) => apps.ObjectMeta.ID === snAccount?.Application?.RefID
            )
        })
      }
      if (createRsrcKey(snApp) === createRsrcKey(app)) {
        return true
      }
    } else {
      return signInAllowed
    }
  }

  const snowflakeSync = async () => {
    setShowScanning(true)
    const currentAccount = await reduxApiClient('snowflakeaccounts').getAll({})
    let filteredAccount = currentAccount?.find((obj) => obj.Application.RefID === app.ObjectMeta.ID)

    if (filteredAccount?.Status?.Status) {
      filteredAccount = {
        ...filteredAccount,
        Status: {
          ...filteredAccount.Status,
          Status: {
            Status: 'started',
            Error: ''
          }
        }
      }

      await reduxApiClient('snowflakeaccounts').update(filteredAccount)
      let pollcount = 0
      const checkSyncStatus = async () => {
        const currentAccount = await reduxApiClient('snowflakeaccounts').getAll({})
        pollcount = pollcount + 1
        const filteredAccount = currentAccount?.find(
          (obj) => obj.Application.RefID === app.ObjectMeta.ID
        )
        if (filteredAccount.Status.Status.Status === 'success') {
          clearInterval(pollingInterval.current)
          enqueueNotification('Sync updated', 'info')
          setUpdateState(true)
          setShowScanning(false)
        }
      }

      pollingInterval.current = setInterval(checkSyncStatus, 10000)
    } else {
      setShowScanning(false)
    }
  }

  const handleSync = () => {
    snowflakeSync()
  }

  return (
    <div>
      <Header
        onAccessClick={handleAccessClick}
        disableAccessBtn={getDisableState()} //disable when cart is not empty and disable if it is userview and the app is already allowed access
        onDelete={handleDeleteClick}
        onEdit={handleEditClick}
        showSignIn={getSignInStatus()}
        app={app}
        onSync={handleSync}
        showScanning={showScanning}
        isSyncDisabled={false}
        setShowCreateRoleModal={setShowCreateRoleModal}
      />
      <div className='mt-6'>
        <Tabs />
      </div>
      {showDeleteAppModal && (
        <DeleteApplicationModal
          cleanUpFn={() => {
            if (canGoBack) history.goBack()
            else history.push(`/${appView}/datacloud`)
          }}
          onCancel={() => setShowDeleteAppModal(false)}
          app={app}
        />
      )}
      {showEditAppModal && (
        <FullScreenContentModal>
          <AddCloudStore
            type=''
            onComplete={() => setShowEditAppModal(false)}
            app={app}
            onClose={() => setShowEditAppModal(false)}
          />
        </FullScreenContentModal>
      )}

      {showCreateRoleModal && (
        <CreateRoleModal
          showCreateRoleModal={showCreateRoleModal}
          setShowCreateRoleModal={setShowCreateRoleModal}
        />
      )}

      {appView === 'admin' && (
        <CreateApplicationPolicyFlow views={views} setViews={handleViewsChange} />
      )}
      {appView === 'user' && <CreateApplicationRequestFlow setViews={setViews} views={views} />}
    </div>
  )
}

const WrappedApplicationDetails = () => {
  const { appView } = useAppView()
  const cartKey = appView === 'admin' ? ADMIN_CART_KEY : USER_CART_KEY

  return (
    <ApplicationDetailsProvider>
      <AccessCartProvider key={cartKey} cartKey={cartKey}>
        <CloudStoreDetails />
      </AccessCartProvider>
    </ApplicationDetailsProvider>
  )
}

export { WrappedApplicationDetails as CloudStoreDetails }
