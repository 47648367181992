import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import _ from 'lodash'
import { TargetIcon } from 'procyon-ui'
import React from 'react'
import {
  AppHTTPEntryPathKey,
  GcpWFFAppPoolID,
  GcpWFFProviderID,
  ReadOnlyAppTypes,
  UIApplicationTypeIconMap
} from './constants'
import { getCurrentOrg } from 'Utils/Helpers'
import store from 'infra/redux/store'
/**
 * Returns URL which can be used for signin into the application
 * @param {object} app
 * @returns
 */
export const getApplicationSignInURL = (app) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const tenantProfiles = Object.values(store.getState().tenantprofiles.map)
  const isAgentlessMode = user.awsurl !== 'signin.aws.procyon.ai'
  const profile = tenantProfiles.find((e) => e.ObjectMeta.Name === user.ObjectMeta.Tenant)
  const isInterOpModeEnabled = profile?.Spec.TunnelConfig.NoDnsProxyMode
  const port = isInterOpModeEnabled ? ':8643' : ''
  const appType = app.Spec.AppType
  let signInUrl
  switch (appType) {
    case 'github':
      signInUrl = _.get(app, 'Spec.SamlServiceProvider.ServiceProviderACSURL').replace(
        /saml$/,
        'sso'
      )
      break

    case 'salesforce':
      signInUrl = _.get(app, 'Spec.SalesforceAppConfig.InstanceURL')
      break

    case 'snowflake':
      if (isInterOpModeEnabled) {
        signInUrl = `https://${user.tenant}.saml.${user.controller}${port}/saml/application?Type=snowflake&Name=${app.ObjectMeta.Name}`
      } else {
        signInUrl = `https://${user.tenant}.saml.${user.controller}/saml/application?Type=snowflake&Name=${app.ObjectMeta.Name}`
      }
      break
    case 'gcp-federation':
      return getGCPWFFederationAppSignInURL(app)
    default:
      // Get HTTP Entry Path
      const entryPath = _.get(app, AppHTTPEntryPathKey, '/')
      const appSignInUrl =
        _.get(app, 'Spec.SamlServiceProvider.ServiceProviderACSURL') ||
        `https://${_.get(app, 'Spec.HttpAppConfig.FrontEndName.Elems[0]', '')}${entryPath}`
      const agentLessSignInUrl = appSignInUrl
        .replace('.default', '-app-default')
        .replace('procyon.ai', user.rdpurl)
      signInUrl = isAgentlessMode ? agentLessSignInUrl : appSignInUrl
      break
  }
  return signInUrl
}

export const getApplicationIcon = (app) => {
  const type = getApplicationType(app)
  //@ts-ignore
  const Icon = ({ ...rest }) => <TargetIcon type={UIApplicationTypeIconMap[type]} {...rest} />
  return Icon
}

/**
 * Returns the type of application
 * @param {*} app
 * @returns {'AWS'|'AZURE'|'GCP'|'DROPBOX'|'HTTP'|'GITHUB'|'SALESFORCE'|'gcp-federation'} Application Type
 */
export const getApplicationType = (app) => {
  const account = getObjectRefFlat(app?.Spec?.Account)
  const serviceType = account ? account.Spec.Type : app.Spec.AppType.toUpperCase()
  return serviceType
}

/**
 * Get the name from frontend url
 * @param {string} fnURL The frontend url in format: <some name>.applications.procyon.ai
 * @returns the <some name> from `fnURL`
 */
export const getFrontendNameFromURL = (fnURL = '') => {
  const withoutAppDomain = fnURL
    .replace(`.${getCurrentOrg()}.applications.procyon.ai`, '')
    .replace('https://', '')
    .replace(/:\d+$/, '')

  return withoutAppDomain
}

/**
 * Returns `true` if this app is readonly - cannot be edited or deleted
 */
export const isReadOnlyApp = (app) => {
  const type = getApplicationType(app)
  if (type === 'gcp-federation') {
    // GCP Federation config account/WF Fed Account
    if (app.Spec.GCPFederationConfig.PoolID && app.Spec.GCPFederationConfig.ProviderID) {
      return true
    }
  }

  return !!ReadOnlyAppTypes[type]
}

export const getApplicationHostName = (application) => {
  const FrontendName = _.get(application, 'Spec.HttpAppConfig.FrontEndName.Elems', [])[0]
  const SAMLACSURL = _.get(application, 'Spec.SamlServiceProvider.ServiceProviderACSURL')
  return FrontendName || SAMLACSURL
}

export const getGCPWFFederationAppSignInURL = (app) => {
  const poolID = _.get(app, GcpWFFAppPoolID, '')
  const providerID = _.get(app, GcpWFFProviderID, '')
  return `https://auth.cloud.google/signin/locations/global/workforcePools/${poolID}/providers/${providerID}?continueUrl=https://console.cloud.google/`
}
