import { FullScreenContentModal } from 'V2Components'
import { AllApplicationData, UIApplicationTypeIconMap } from 'features/applications'
import { IntegrationCard } from 'procyon-ui'
import React, { useState } from 'react'
import { AddApplication } from '../../../../AddApplication'

const AllApplications = () => {
  const [selectedType, setSelectedType] = useState('')
  const [showAddApplication, setShowAddApplication] = useState(false)

  const handleInfoModalClick = (type) => setSelectedType(type)

  const handleIntegrationModalClick = (type) => {
    setShowAddApplication(true)
    if (type === 'WEB API (HTTP)') setSelectedType('HTTP')
    else setSelectedType(type)
  }

  const getAppIconType = (type) => {
    return UIApplicationTypeIconMap[type.toUpperCase()] || 'REST_API'
  }

  return (
    <>
      <div className='grid gap-6 my-10 grid-cols-3'>
        {AllApplicationData.map((item) => (
          <IntegrationCard
            caption={item.caption}
            iconType={getAppIconType(item.type)}
            onClickAuthorizeButton={function noRefCheck() {}}
            onClickInfoButton={() => handleInfoModalClick(item.type.toLowerCase())}
            onClickIntegrateButton={() => handleIntegrationModalClick(item.type.toUpperCase())}
            onClickManageIntegrationButton={function noRefCheck() {}}
            onClickRemoveButton={function noRefCheck() {}}
            showInfoButton={false}
            showIntegrateButton
            subtitle={item.category}
            title={item.type}
            className='mr-10'
          />
        ))}

        {showAddApplication && (
          <FullScreenContentModal>
            <AddApplication
              type={selectedType}
              onComplete={() => setShowAddApplication(false)}
              onClose={() => setShowAddApplication(false)}
            />
          </FullScreenContentModal>
        )}
      </div>
    </>
  )
}

export { AllApplications }
